import _ from '../../core/utils'
import videoGalleryConfig from './config'

class VideoGalleryClass {
    constructor( gallery ) {
        this.DOM = {
            gallery: gallery,
            iframe: _.select( '.video iframe', gallery ),
            triggers: _.selectAll( '.video__trigger', gallery )
        }

        this.init()
    }

    init() {
        this._listeners()
        console.log( this )
    }

    _listeners() {
        this.DOM.triggers.forEach( ( trigger ) => { trigger.addEventListener( 'click', this._videoChange.bind( this ) ) })
    }

    _videoChange() {
        let trigger = event.currentTarget
        if ( !_.containClass( trigger, 'is--active' ) ) {
            let id = trigger.getAttribute( 'data-video-id' ),
                current = _.select( '.video__trigger.is--active', this.DOM.gallery )

            this.DOM.iframe.src = `https://www.youtube.com/embed/${id}`
            _.removeClass( current, 'is--active' )
            _.addClass( trigger, 'is--active' )

        }
    }
}

const VideoGalleries = []
videoGalleryConfig.galleries.forEach ( ( gallery ) => {
    VideoGalleries.push( new VideoGalleryClass( gallery ) )
})

