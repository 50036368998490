import _ from '../../core/utils'
import sliderPostFloated from './config'
import Flickity from 'flickity-fade'

class SliderPostFloatedClass {
    constructor( section ) {
        this.DOM = {
            section: section,
            slider: _.select( '.slider__wrap', section ),
            slides: _.selectAll( '.slide', section ),
            controls: Array.from( _.selectAll( '.slider__control', section ) )
        }

        this.flickity = null
        this.init()
    }

    init() {
        console.log( this )
        this._flickity()
    }

    _flickity() {
        let THIS = this
        this.flickity = new Flickity( this.DOM.slider, {
            prevNextButtons: false,
            pageDots: false,
            cellAlign: 'left',
            contain: true,
            wrapAround: true,
            on: {
                ready: function () {
                    THIS._ctrls()
                }
            }
        })
    }

    _ctrls() {
        this.DOM.controls.forEach( ( control ) => {
            control.addEventListener( 'click', () => {
                _.containClass( control, 'slider__control--right' ) ? this.flickity.next() : this.flickity.previous()
            }, false )
        })
    }

}

const SlidersPostFloated = []
sliderPostFloated.sections.forEach( ( section ) => {
    SlidersPostFloated.push( new SliderPostFloatedClass( section ) )
})
export default SlidersPostFloated