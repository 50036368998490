import _ from '../../core/utils'
import headerConfig from './config'
import { gsap } from 'gsap'

class HeaderClass {
    constructor() {
        this.DOM = {
            el: headerConfig.el,
            sectionFirst: headerConfig.sectionFirst,
            navWrap: headerConfig.navWrap,
            doc: headerConfig.doc,
            nav: headerConfig.nav,
            hamburger: headerConfig.hamburger,
            navs: headerConfig.navs,
            subnavTrigger: headerConfig.subnavTrigger,
            subnavTriggerSecondary: headerConfig.subnavTriggerSecondary
        }
        this.treshold = headerConfig.treshold,
        this.breakpoint = headerConfig.breakpoint
        this.direction = null
        this.y = window.scrollY
        this.mobileState = false

        this.init()
    }

    init() {
        console.log( this )
        this._breakpointCheck()
    }
    _breakpointCheck() {
        this.DOM.doc.clientWidth > this.breakpoint ? this._headerDesktop() : this._headerMobile()
    }
    _headerDesktop() {
        this._scrollListener()
        this._navsListeners()
        this._headerHeight()
    } 
    _scrollListener() {
        window.addEventListener( 'scroll', this._scrollPosition.bind( this ) )
    }
    _scrollPosition( event ) {
        window.scrollY > this.y ? this.direction = 'down' : this.direction = 'up'
        this.y = window.scrollY

        this._headerVisibility()
    }
    _headerVisibility() {
        this.direction == 'down' && this.y > this.treshold ? this._headerHide() : this._headerShow() 
    }
    _headerHide() {
        gsap.to( this.DOM.el, {
            top: -this.DOM.el.clientHeight,
            duration: .4
        })
        this.DOM.nav ? this._navUnpush() : null
    } 
    _headerShow() {
        gsap.to( this.DOM.el, {
            top: 0,
            duration: .4
        })
        this.DOM.nav ? this._navPush() : null
    }
    _headerHeight() {
        let gap = this.DOM.el.clientHeight
        this.DOM.sectionFirst.style.paddingTop = gap + 'px'
    }
    _navPush() {
        gsap.to( this.DOM.el, {
            top: 80,
            duration: .4
        })
    }
    _navUnpush() {
        gsap.to( this.DOM.el, {
            top: 0,
            duration: .4
        })
    }
    _headerMobile() {
        let gap = this.DOM.el.clientHeight
        //1this.DOM.navWrap.style.paddingTop = gap + 'px'
        this._headerMobileHamburgerListener()
    }
    _headerMobileHamburgerListener() {
        this.DOM.hamburger.addEventListener( 'click', this._headerMobileState.bind( this ) )
        this._mobileSubnavListener()
        this._mobileSubnavSecondaryListener()
    }
    _headerMobileState() {
        this.mobileState ? _.removeClass( document.documentElement, 'nav--expanded' ) : _.addClass( document.documentElement, 'nav--expanded' )
        this.mobileState = !this.mobileState
        !this.mobileState ? this._navsHide() : null
    }  
    _navsListeners() {
        this.DOM.navs.forEach( ( nav ) => {
            nav.addEventListener( 'mouseenter', this._navsActive.bind( this ) )
            nav.addEventListener( 'mouseleave', this._navsUnactive.bind( this ) )
        })
    }
    _navsActive() {
        document.documentElement.style.overflowY = 'hidden'
        _.addClass( this.DOM.el, 'is--subnav' )
    }
    _navsUnactive() {
        document.documentElement.style.overflowY = 'initial'
        _.removeClass( this.DOM.el, 'is--subnav' )
    }
    _mobileSubnavListener() {
        this.DOM.subnavTrigger.forEach( ( trigger ) => {
            trigger.addEventListener( 'click', this._mobileSubnavTriggered.bind( this ) )
        })
    }
    _mobileSubnavTriggered() {
        event.preventDefault()
        let target = event.currentTarget,
            ancestor = _.findAncestor( target, 'li' ),
            id = target.getAttribute( 'data-subnav' ),
            subnav = _.select( `.subnav[data-subnav="${id}"]` )
            console.log( target )
        
        if( _.containClass( subnav, 'is--active' ) ) {
            _.removeClass( subnav, 'is--active' )
            _.removeClass( target, 'is--active' )
        } else {
            _.addClass( subnav, 'is--active' )
            _.addClass( target, 'is--active' )
        }
    }
    _mobileSubnavSecondaryListener() {
        this.DOM.subnavTriggerSecondary.forEach( ( trigger ) => {
            trigger.addEventListener( 'click', this._mobileSubnavSecondaryTriggered.bind( this ) )
        })
    }
    _mobileSubnavSecondaryTriggered() {
        event.preventDefault()
        let target = event.currentTarget,
            ancestor = _.findAncestor( target, 'block' ),
            grids = _.selectAll( '.grid', ancestor ),
            uls = _.selectAll( 'ul', ancestor ),
            footers = _.selectAll( 'footer', ancestor )

            if ( _.containClass( target, 'is--active' ) ) {
                _.removeClass( target, 'is--active' )
                grids.forEach( ( grid ) => { _.removeClass( grid, 'is--active' ) })
                uls.forEach( ( ul ) => { _.removeClass( ul, 'is--active' ) })
                footers.forEach( ( footer ) => { _.removeClass( footer, 'is--active' ) })
            } else {
                _.addClass( target, 'is--active' )
                grids.forEach( ( grid ) => { _.addClass( grid, 'is--active' ) })
                uls.forEach( ( ul ) => { _.addClass( ul, 'is--active' ) })
                footers.forEach( ( footer ) => { _.addClass( footer, 'is--active' ) })
            }
    }
    _navsHide() {        
        this.DOM.subnavTrigger.forEach( ( trigger ) => {
            let target = trigger,
                ancestor = _.findAncestor( target, 'li' ),
                subnav = _.select( '.subnav', ancestor )
            _.removeClass( subnav, 'is--active' )
            _.removeClass( target, 'is--active' )
        })
        this.DOM.subnavTriggerSecondary.forEach( ( trigger ) => {
            let target = trigger,
                ancestor = _.findAncestor( target, 'block' ),
                grids = _.selectAll( '.grid', ancestor ),
                uls = _.selectAll( 'ul', ancestor ),
                footers = _.selectAll( 'footer', ancestor )
            _.removeClass( target, 'is--active' )
                grids.forEach( ( grid ) => { _.removeClass( grid, 'is--active' ) })
                uls.forEach( ( ul ) => { _.removeClass( ul, 'is--active' ) })
                footers.forEach( ( footer ) => { _.removeClass( footer, 'is--active' ) })
        })
    }
}

const header = new HeaderClass()
export default header