import _ from '../../core/utils'
import sliderBesaLabConfig from './config'
import Flickity from 'flickity-fade'

class SliderSectionClass {
    constructor( section ) {
        this.DOM = {
            section: section,
            slider: _.select( '.slider__wrap', section ),
            slides: _.selectAll( '.slide', section ),
            controls: Array.from( _.selectAll( '.slider__controls button', section ) ),
            backgrounds: Array.from( _.selectAll( '.background', section ) )
        }

        this.breakpoint = sliderBesaLabConfig.breakpoint
        this.initialIndex = 1
        this.cellAlign = 'center'
        this.autoPlay = sliderBesaLabConfig.autoPlay
        this.flickity = null
        this.init()
    }

    init() {
        console.log( this )
        this._responsive()
        this._flickity()
    }

    _responsive() {
        if ( window.innerWidth <= this.breakpoint ) {
            this.cellAlign = 'left'
            this.initialIndex = 0
        }
    }

    _flickity() {
        let THIS = this
        this.flickity = new Flickity( this.DOM.slider, {
            prevNextButtons: false,
            pageDots: true,
            cellAlign: this.cellAlign,
            initialIndex: this.initialIndex,
            on: {
                ready: function () {
                    THIS._ctrls()
                },
                change: ( index ) => {
                    THIS._change( index )
                }
            }
        })
    }

    _ctrls() {
        this.DOM.controls.forEach( ( control ) => {
            control.addEventListener( 'click', () => {
                _.containClass( control, 'slider__control--right' ) ? this.flickity.next() : this.flickity.previous()
            }, false )
        })
    }

    _change( index ) {
        let previous = _.select( '.slider__control--left', this.DOM.section )
        let next = _.select( '.slider__control--right', this.DOM.section )
        if ( index == 0 ) {
            previous.style.opacity = '0'
        } else {
            previous.style.opacity = '100'
        }
        if ( this.DOM.slides.length - 1 == index ) {
            next.style.opacity = '0'
        } else {
            next.style.opacity = '100'
        }
    }

}

const SliderSections = []
sliderBesaLabConfig.sections.forEach( ( section ) => {
    SliderSections.push( new SliderSectionClass( section ) )
})
export default SliderSections