import _ from '../../core/utils'
import loadMoreConfig from './config'

class LoadMoreClass {
    constructor() {
        this.DOM = {
            el: loadMoreConfig.el,
            items: _.selectAll( '.besa__load-more-item', loadMoreConfig.el ),
            button: _.select( '.besa__load-more-footer button', loadMoreConfig.el )
        }
        this.initialAmount = 0
        this.amount = 0
        this.init()
    }

    init() {
        console.log( this )
        this.DOM.el ? this._listeners() : null
        this.DOM.el ? this._setInitialAmount() : null
        this.DOM.el ? this._displayItems() : null
    }

    _listeners() {
        this.DOM.button.addEventListener( 'click', this._loadMore.bind( this ) )
    }

    _setInitialAmount() {
        this.initialAmount = parseInt( this.DOM.el.getAttribute( 'data-load-more-initial' ) )
        this.amount = this.initialAmount
    }

    _displayItems() {
        this.DOM.items.forEach( ( item, index ) => {
            index >= this.amount ? _.addClass( item, 'besa__load-more-item-hide' ) : _.removeClass( item, 'besa__load-more-item-hide' )
        })
        this._buttonDisplay()
    }

    _loadMore() {
        this.amount += this.initialAmount
        this._displayItems()
    }

    _buttonDisplay() {
        let itemsHidedLength = _.selectAll( '.besa__load-more-item-hide', this.DOM.el ).length
        itemsHidedLength === 0 ? this.DOM.button.style.display = 'none' : null
    }

}

const loadmore = new LoadMoreClass()
export default loadmore