import _ from '../../core/utils'
import sliderSimpleConfig from './config'
import Flickity from 'flickity-fade'

class SliderSimpleClass {
    constructor( slider ) {
        this.DOM = {
            slider: slider,
            slides: _.selectAll( '.slide', slider ),
            nav: null
        }

        this.flickity = null
        this.init()
    }

    init() {
        this._flickity()
    }

    _flickity() {
        let THIS = this
        this.flickity = new Flickity( this.DOM.slider, {
            prevNextButtons: false,
            pageDots: true,
            on: {
                ready: () => {
                    this._hideNav()
                }
            }
        })
    }
    
    _hideNav() {
        this.DOM.nav = _.select( '.flickity-page-dots', this.DOM.slider )
        let length = this.DOM.slides.length
       
        length === 1 ? this.DOM.nav.style.display = 'none' : null
    }

}

const SliderSimples = []
sliderSimpleConfig.sliders.forEach( ( slider ) => {
    SliderSimples.push( new SliderSimpleClass( slider ) )
})
export default SliderSimples