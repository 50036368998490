import _ from '../../core/utils'
import filterBesalabConfig from './config'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'
gsap.registerPlugin( ScrollToPlugin )

class FilterBesalabClass {
    constructor() {
        this.DOM = {
            el: filterBesalabConfig.el,
            inputs: _.selectAll( '.filter__input', filterBesalabConfig.el ),
            items: _.selectAll( '.item', filterBesalabConfig.el )
        }

        this.filtered = []
        this.init()
    }

    init() {
        if ( this.DOM.el ) {
            this._listeners()
        }
    }

    _listeners() {
        this.DOM.inputs.forEach( ( input ) => { input.addEventListener( 'click', this._inputTrigger.bind( this )) })
    }

    // Checbkox triggered
    _inputTrigger() {
       
        let checkbox = event.target,
            value = event.target.value,
            checked = event.target.checked

        checked ? this.filtered.push( value ) : this.filtered = this.filtered.filter( ( e ) => { return e !== value })

        this.filtered.length > 0 ? this._itemFilter() : this._itemsUnfilter()
    }

    _itemFilter() {
        this.DOM.items.forEach( ( item ) => {
            let itemDataFilter = item.getAttribute( 'data-filter' ),
                itemFilterArray = itemDataFilter.split( ',' ),
                coincidence = 0
            
            // Compare filter array with item filters
            for ( let value of this.filtered ) {
                if ( itemFilterArray.includes( value ) ) {
                    coincidence += 1
                } 
            }
            
            // Show / Hide depending on filters coincidence
            coincidence != 0 ? _.removeClass( item, 'item--filtered' ) : _.addClass( item, 'item--filtered' )

        })
    }

    // Delete filters
    _itemsUnfilter() {
        this.DOM.inputs.forEach( ( input ) => { 
            input.checked = false
            this.filtered = []
        })
        this.DOM.items.forEach( ( item ) => {
            _.removeClass( item, 'item--filtered' )
        })
        
    }

}

const FilterBesalab = new FilterBesalabClass()
export default FilterBesalab