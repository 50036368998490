export default _ = {
    select: (selector, scope) => {
        if (!selector) throw new Error('Please, provide a selector')
        return scope ? scope.querySelector(selector) : document.querySelector(selector)
    },

    // Replace querySelectorAll
    selectAll: (selector, scope) => {
        if (!selector) throw new Error('Please, provide a selector')
        return scope ? scope.querySelectorAll(selector) : document.querySelectorAll(selector)
    }, 

    // Add class
    addClass: ( element, className ) => {
        element.classList.add( className )
    },

    // Remove class
    removeClass: ( element, className ) => {
        element.classList.remove( className )
    },

    // Contain class
    containClass: (element, className ) => {
        if (element.classList.contains( className )) {
            return true
        }
    },

    // Find ancestor by class
    findAncestor: (element, className) => {
        while ((element = element.parentElement) && !element.classList.contains(className));
        return element;
    },
}