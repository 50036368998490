import _ from '../../core/utils'
import checkboxGroupRequiredConfig from './config'

class CheckboxGroupRequiredClass {
    constructor( el ) {
        this.DOM = {
            group: el,
            inputs: _.selectAll( 'input[type="checkbox"]', el ),
            validator: _.select( '.checkbox__required', el )
        }

        this.checkeds = 0
        this.init()
    }

    init() {
        console.log( this )
        this._listeners()
    }

    _listeners() {
        this.DOM.inputs.forEach( ( input ) => { input.addEventListener( 'change', this._change.bind( this ) ) })
    }

    _change() {
        let input = event.currentTarget
        input.checked ? this.checkeds += 1 : this.checkeds -= 1
        console.log( this.checkeds )
        this._validation()
    }

    _validation() {
        this.checkeds > 0 ? this.DOM.validator.value = 'ok' : this.DOM.validator.value = null
        console.log( this.DOM.validator.value )
    }

    
}

const checkboxGroupsRequired = []
checkboxGroupRequiredConfig.groups.forEach( ( group ) => {
    checkboxGroupsRequired.push( new CheckboxGroupRequiredClass( group ) )
})

export default checkboxGroupsRequired