import _ from '../../core/utils'
import productsConfig from './config'

class ProductsClass {
    constructor( section ) {
        this.DOM = {
            section: section,
            filterMobile: _.select( '.block__mobile', section ),
            filterMobileState: false,
            filters: _.selectAll( '.block__filter', section )
        }

        this.init()
    }

    init() {
        this.DOM.section && this.DOM.filterMobile ? this._listeners() : null
    }

    _listeners() {
        this.DOM.filterMobile.addEventListener( 'click', this._filterMobileListener.bind( this ) )
    }

    _filterMobileListener() {
        this.filterMobileState = !this.filterMobileState
        this.DOM.filters.forEach( ( filter ) => {
            if ( this.filterMobileState ) {
                _.addClass( this.DOM.filterMobile, 'is--active' )
                _.addClass( filter, 'is--active' )
            } else {
                _.removeClass( this.DOM.filterMobile, 'is--active' )
                _.removeClass( filter, 'is--active' )
            }
        })
    }
}

const products = new ProductsClass( productsConfig.section )
export default products