
import _ from '../../core/utils'
import sliderSectionConfig from './config'
import Flickity from 'flickity-fade'
import { gsap } from 'gsap'

class SliderSectionClass {
    constructor( section ) {
        this.DOM = {
            section: section,
            slider: _.select( '.slider__wrap', section ),
            controls: Array.from( _.selectAll( '.slider__controls button', section ) ),
            backgrounds: Array.from( _.selectAll( '.background', section ) )
        }
        this.barTl = null
        this.autoPlay = sliderSectionConfig.autoPlay
        this.flickity = null
        this.init()
    }

    init() {
        this._flickity()
        this._listeners()
    }

    _listeners() {
        this.DOM.slider.addEventListener( 'mouseenter', this._sliderMouseEnter.bind( this ) )
        this.DOM.slider.addEventListener( 'mouseleave', this._sliderMouseLeave.bind( this ) )
    }

    _flickity() {
        let THIS = this
        this.flickity = new Flickity( this.DOM.slider, {
            prevNextButtons: false,
            pageDots: false,
            fade: true,
            autoPlay: 4000,
            pauseAutoPlayOnHover: true,
            on: {
                ready: function () {
                    THIS._ctrls()
                    THIS._change( 0 )
                },
                change: ( index ) => {
                    THIS._change( index )
                }
            }
        })
    }

    _ctrls() {
        this.DOM.controls.forEach( ( control ) => {
            control.addEventListener( 'click', () => {
                let slideIndex = this.DOM.controls.indexOf( control )
                this.flickity.select( slideIndex )
            }, false )
        })
    }

    _change( index ) {
        this.tl = null
        let barPast = _.select( '.slider__controls button.is--active .ctrl__bar span', this.DOM.section )
        let barActive = _.select( '.ctrl__bar span', this.DOM.controls[index])

        _.removeClass( _.select( '.slider__controls button.is--active', this.DOM.section ), 'is--active' )
        !_.containClass( this.DOM.section, 'section--default-slider--negative' ) ? _.removeClass( _.select( '.background.is--active', this.DOM.section ), 'is--active' ) : null
        gsap.to( barPast, {
            width: 0,
            duration: .1
        })

        _.addClass( this.DOM.controls[ index ], 'is--active' )
        !_.containClass( this.DOM.section, 'section--default-slider--negative' ) ? _.addClass( this.DOM.backgrounds[ index ], 'is--active' ) : null
        this.tl = gsap.timeline()
        this.tl.to( barActive, {
            width: '100%',
            duration: this.autoPlay * 0.001
        })
    }

    _sliderMouseEnter() {
        this.tl.pause()
    }

    _sliderMouseLeave() {
        this.tl.play()
    }

}

const SliderSections = []
sliderSectionConfig.sections.forEach( ( section ) => {
    SliderSections.push( new SliderSectionClass( section ) )
})
export default SliderSections






/*
import _ from '../../core/utils'
import sliderSectionConfig from './config'
import Flickity from 'flickity-fade'

class SliderSectionClass {
    constructor( section ) {
        this.DOM = {
            section: section,
            slider: _.select( '.slider__wrap', section ),
            controls: Array.from( _.selectAll( '.slider__controls button', section ) ),
            backgrounds: Array.from( _.selectAll( '.background', section ) )
        }

        this.autoPlay = sliderSectionConfig.autoPlay
        this.flickity = null
        this.init()
    }

    init() {
        console.log( this )
        console.log ( "voy")
        this._flickity()
    }

    _flickity() {
        let THIS = this
        this.flickity = new Flickity( this.DOM.slider, {
            prevNextButtons: false,
            pageDots: false,
            fade: true,
            autoPlay: 4000,
            on: {
                ready: function () {
                    THIS._ctrls()
                },
                change: ( index ) => {
                    THIS._change( index )
                }
            }
        })
    }

    _ctrls() {
        this.DOM.controls.forEach( ( control ) => {
            control.addEventListener( 'click', () => {
                let slideIndex = this.DOM.controls.indexOf( control )
                this.flickity.select( slideIndex )
            }, false )
        })
    }

    _change( index ) {
        _.removeClass( _.select( '.slider__controls button.is--active', this.DOM.section ), 'is--active' )
        !_.containClass( this.DOM.section, 'section--default-slider--negative' ) ? _.removeClass( _.select( '.background.is--active', this.DOM.section ), 'is--active' ) : null
        _.addClass( this.DOM.controls[ index ], 'is--active' )
        !_.containClass( this.DOM.section, 'section--default-slider--negative' ) ? _.addClass( this.DOM.backgrounds[ index ], 'is--active' ) : null
    }

}

const SliderSections = []
sliderSectionConfig.sections.forEach( ( section ) => {
    SliderSections.push( new SliderSectionClass( section ) )
})
export default SliderSections
*/