import _ from '../../core/utils'
const headerConfig = {
    el: _.select( '.header--main' ),
    sectionFirst: _.select( '.section:first-of-type' ),
    navWrap: _.select( '.nav__wrap' ),
    treshold: 100,
    breakpoint: 1024,
    doc: document.documentElement,
    nav: _.select( '.section--page-nav' ),
    hamburger: _.select( '.header--main .hamburger' ),
    navs: _.selectAll( '.header--main .block--nav nav > ul > li' ),
    subnavs: _.selectAll( '.header--main .block--nav > ul > li > .subnav'),
    subnavTrigger: _.selectAll( '.header--main .subnav__trigger' ),
    subnavTriggerSecondary: _.selectAll( '.header--main .subnav__trigger--secondary')
}

export default headerConfig