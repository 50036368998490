import _ from '../../core/utils'
import accordionConfig from './config'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'
gsap.registerPlugin( ScrollToPlugin )

class AccordionClass {
    constructor( accordion ) {
        this.DOM = {
            accordion: accordion,
            triggers: _.selectAll( '.accordion__trigger', accordion ),
            contents: _.selectAll( '.accordion__content', accordion )
        }
        this.init() 
    }

    init() {
        this._listeners()
        this._accordionInitial()
        console.log( this )
    }

    _listeners() {
        this.DOM.triggers.forEach( ( trigger ) => {
            trigger.addEventListener( 'click', this._accordionTrigger.bind( this ) )
        })
    }

    _accordionInitial() {
        _.containClass( this.DOM.accordion, 'besa__accordion--initial' ) ? this._accordionInitialDisplay() : null
    }

    _accordionInitialDisplay() {
        _.addClass( this.DOM.triggers[0], 'is--active' )
        _.addClass( this.DOM.contents[0], 'is--active' )
    }

    _accordionScroll( target ) {
        if ( !_.containClass( this.DOM.accordion, 'besa__accordion--noscroll' ) ) {
            gsap.to( window, {
                duration: .3, 
                scrollTo: {
                    y:  target,
                    offsetY: 200
                }
            });
        }
    }


    _accordionTrigger() {
        let target = event.currentTarget
        // add class to current triggered element
        if( _.containClass( target, 'is--active' ) ) {
            this._accordionUnclass()
        } else {
            this._accordionUnclass()
            this._accordionClass( target )
        } 
        this._accordionScroll( target )
    }

    _accordionClass( target ) {
        let element = event.currentTarget.parentNode,
        content = _.select( '.accordion__content', element )
        _.addClass( target, 'is--active' )
        _.addClass( content, 'is--active' )

    }

    _accordionUnclass() {
        this.DOM.triggers.forEach( ( trigger ) => {
            _.removeClass( trigger, 'is--active' )
        })
        this.DOM.contents.forEach( ( content ) => {
            _.removeClass( content, 'is--active' )
        })
    }

    

    
}

const accordions = []
accordionConfig.accordions.forEach( ( accordion ) => {
    accordions.push( new AccordionClass( accordion ) )
})
export default accordions