import _ from '../../core/utils'
import industryConfig from './config'

class IndustryClass {
    constructor() {
        this.DOM = {
            page: industryConfig.page,
            stamps: industryConfig.stamps,
            stampsMore: industryConfig.stampsMore
        }

        this.stampsMoreInitial = industryConfig.stampsMoreInitial
        this.stampsLength = this.DOM.stamps.length
        this.stampsMoreBreakpoint = industryConfig.stampsMoreBreakpoint

        this.init()
    }

    init() {
        console.log( this )
       if ( this.DOM.page ) {
           this._listeners()
           window.innerWidth <= this.stampsMoreBreakpoint ? this._stampsInitialHide() : null
       }
    }

    _listeners() {
        this.DOM.stampsMore.addEventListener( 'click', this._stampsShowMore.bind( this ) )
    }

    _stampsInitialHide() {
        console.log( 'Initial hide' )
        this.DOM.stamps.forEach( ( stamp, index ) => {
            console.log( index )
            console.log( stamp )
            if ( index >= this.stampsMoreInitial ) {
                stamp.style.display = 'none'
            }
        })
    }

    _stampsShowMore() {
        this.DOM.stamps.forEach( ( stamp, index ) => {
            stamp.style.display = 'flex'
            this.DOM.stampsMore.style.display = 'none'
        })
    }
}

const industry = new IndustryClass()
export default industry