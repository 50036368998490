import _ from '../../core/utils'
import searchConfig from './config'

class Search {
    constructor() {
        this.DOM = {
            el: searchConfig.el,
            header: searchConfig.header,
            form: searchConfig.form,
            triggers: searchConfig.triggers,
            triggerDesktop: searchConfig.triggerDesktop
        }

        this.breakpoint = searchConfig.breakpoint
        this.state = false

        this.init()
    }

    init() {
        this._listeners()
        this._searchSizes()
    }

    _listeners() {
        this.DOM.triggers.forEach( ( trigger ) => {
            trigger.addEventListener( 'click', this._searchState.bind( this ) )
        })
    }

    _searchSizes() {
        let windowWidth = window.innerWidth,
            desktopTriggerHeight = this.DOM.triggerDesktop.clientHeight - 35,
            desktopTriggerPosition = this.DOM.triggerDesktop.getBoundingClientRect()

        windowWidth <= this.breakpoint ? this.DOM.el.style.width = `${windowWidth}px` : this.DOM.el.style.width = `${desktopTriggerPosition.left - 1}px`
        windowWidth <= this.breakpoint ? this.DOM.el.style.height = `auto` : this.DOM.el.style.height = `${desktopTriggerHeight}px`
        
    }

    _searchState() {
        this.state = !this.state
        this.state ? _.addClass( this.DOM.header, 'is--search' ) : _.removeClass( this.DOM.header, 'is--search' )
    }

    
}

const search = new Search()
export default search