import _ from '../../core/utils'
import sliderAboutConfig from './config'
import Flickity from 'flickity-fade'

class SliderAboutClass {
    constructor( section ) {
        this.DOM = {
            section: section,
            slider: _.select( '.slider__wrap', section ),
            slides: _.selectAll( '.slide', section ),
            controls: Array.from( _.selectAll( '.slider__controls button', section ) )
        }

        this.flickity = null
        this.init()
    }

    init() {
        console.log( this )
        this._flickity()
    }

    _flickity() {
        let THIS = this
        this.flickity = new Flickity( this.DOM.slider, {
            prevNextButtons: false,
            pageDots: true,
            cellAlign: 'left',
            contain: true,
            wrapAround: true,
            on: {
                ready: function () {
                    THIS._ctrls()
                },
                change: ( index ) => {
                    THIS._change( index )
                }
            }
        })
        let previous = _.select( '.slider__control--left', this.DOM.section )
        let next = _.select( '.slider__control--right', this.DOM.section )
        next.style.opacity = '100'

    }

    _ctrls() {
        this.DOM.controls.forEach( ( control ) => {
            control.addEventListener( 'click', () => {
                _.containClass( control, 'slider__control--right' ) ? this.flickity.next() : this.flickity.previous()
            }, false )
        })
    }

    _change( index ) {
        let previous = _.select( '.slider__control--left', this.DOM.section )
        let next = _.select( '.slider__control--right', this.DOM.section )
        if ( index == 0 ) {
            previous.style.opacity = '0'
            previous.style.pointerEvents = 'none'
        } else {
            previous.style.opacity = '100'
            previous.style.pointerEvents = 'all'
        }
        console.log( 'Length: ' + this.DOM.slides.length )
            console.log( index )
        if ( this.DOM.slides.length - 1 == index ) {
            next.style.opacity = '0'
            next.style.pointerEvents = 'none'
        } else {
            next.style.opacity = '100'
            next.style.pointerEvents = 'all'
        }
    }

}

const SliderAbouts = []
sliderAboutConfig.sliders.forEach( ( section ) => {
    SliderAbouts.push( new SliderAboutClass( section ) )
})
export default SliderAbouts