import _ from '../../core/utils'

const industryConfig = {
    page: _.select( '.page--industria' ),
    stamps: _.selectAll( '.page--industria .besa__stamps li' ),
    stampsMore: _.select( '.page--industria .more__stamps' ),
    stampsMoreInitial: 8,
    stampsMoreBreakpoint: 1024
}

export default industryConfig