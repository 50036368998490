import _ from '../../core/utils'
import calculatorConfig from './config'
import axios from 'axios'

class CalculatorClass {
    constructor() {
        this.DOM = {
            calculator: calculatorConfig.calculator,
            form: _.select( 'form', calculatorConfig.calculator ),
            result: _.select( '.result .quantity', calculatorConfig.calculator ),
            unity: _.select( '.result .unit', calculatorConfig.calculator ),
            tooltip: _.select( '.besa__tooltip', calculatorConfig.calculator )
        }

        this.constants = {
            solidos: 0,
            densidad: 0,
            mezcla_a: 0,
            mezcla_b: 0,
            unidad: ''
        }

        this.result = 0

        this.init()
    }

    init() {
        this.DOM.calculator ? this._listeners() : null
        console.log( this )
    }

    _listeners() {
        this.DOM.form.addEventListener( 'submit', this._submit.bind( this ) )
        this._getConstants()
    }

    _getConstants() {
        this.constants.solidos = parseFloat( this.DOM.calculator.getAttribute( 'data-solidos' ) )
        this.constants.densidad = parseFloat( this.DOM.calculator.getAttribute( 'data-densidad' ) )
        this.constants.mezcla_a = parseFloat( this.DOM.calculator.getAttribute( 'data-mezcla-a' ) )
        this.constants.mezcla_b = parseFloat( this.DOM.calculator.getAttribute( 'data-mezcla-b' ) )
        this.constants.unidad = this.DOM.calculator.getAttribute( 'data-unidad' )
        console.log( this )
    }

    _submit() {
        event.preventDefault()
        let percent = parseFloat( _.select( 'select[name="percent"]', this.DOM.form ).value ),
            superficie = parseFloat( _.select( 'input[name="superficie"]', this.DOM.form ).value ),
            espesor = parseFloat( _.select( 'input[name="espesor"]', this.DOM.form ).value ),
            mezcla2K = superficie / ( this.constants.solidos * 10 / espesor ),
            mezclaPerdida = mezcla2K * ( percent ) / 100,
            necesaria = mezcla2K + mezclaPerdida,
            catalizador = +(necesaria * this.constants.mezcla_b ) / ( this.constants.mezcla_a + this.constants.mezcla_b ),
            compa = necesaria - catalizador,
            result = 0

            this.result = + compa * this.constants.densidad
            result = (this.result).toFixed( 2 )

            console.log( mezcla2K )
            console.log( mezclaPerdida )
            console.log( necesaria )
            console.log( catalizador )
            console.log( compa )
            console.log( result )
        
        this.DOM.result.innerHTML = result
        this.DOM.unity.innerHTML = this.constants.unidad
        _.addClass( this.DOM.calculator, 'is--calc' )
        _.addClass( this.DOM.tooltip, 'besa__tooltip--white' )
    }

}

const calculator = new CalculatorClass()