import _ from '../../core/utils'
import filterGridConfig from './config'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'
gsap.registerPlugin( ScrollToPlugin )

class FilterGridClass {
    constructor() {
        this.DOM = {
            el: filterGridConfig.el,
            sidebar: _.select( '.sidebar', filterGridConfig.el ),
            search: _.select( '.besa__filter-search input', filterGridConfig.el ),
            blocks: _.selectAll( '.besa__filter-block', filterGridConfig.el ),
            inputs: _.selectAll( '.besa__filter-input', filterGridConfig.el ),
            deletes: _.selectAll( '.besa__filter-delete', filterGridConfig.el ),
            more: _.selectAll( '.besa__filter-more', filterGridConfig.el ),
            load: _.select( '.besa__filter-load', filterGridConfig.el ),
            loader: {
                el: _.select( '.besa__filter-loader', filterGridConfig.el ),
                text: _.select( '.besa__filter-loader .spinner span', filterGridConfig.el )
            },
            items: _.selectAll( '.besa__filter-item', filterGridConfig.el ),
            noresults: _.select( '.besa__filter-noresults', filterGridConfig.el ),
            counter: {
                num: _.select( '.besa__filter-counter-num', filterGridConfig.el ),
                label: _.select( '.besa__filter-counter-label' )
            }
        }

        this.itemsDisplayed = filterGridConfig.initialDisplayLength,
        this.filtered = []
        this.itemsLoadScrollOffset = filterGridConfig.itemsLoadScrollOffset
        this.messages = {
            loader: {
                filter: this.DOM.loader.el ? this.DOM.loader.el.getAttribute( 'data-filter' ) : null,
                search: this.DOM.loader.el ? this.DOM.loader.el.getAttribute( 'data-search' ) : null
            },
            counter: {
                singular: this.DOM.counter.label ? this.DOM.counter.label.getAttribute( 'data-singular' ) : null,
                plural: this.DOM.counter.label ? this.DOM.counter.label.getAttribute( 'data-plural' ) : null
            }
        }

        this.init()
    }

    init() {
        if ( this.DOM.el && !_.containClass( this.DOM.el, 'besa__filter-grid--disabled' ) ) {
            this._listeners()
            this._loadInitial()
            this._itemsCounter()
        }
    }

    _listeners() {
        //this.DOM.load.addEventListener( 'click', this._loadMore.bind( this ) )
        this.DOM.inputs.forEach( ( input ) => { input.addEventListener( 'click', this._inputTrigger.bind( this )) })
        this.DOM.deletes.forEach( ( input ) => { input.addEventListener( 'click', this._itemsUnfilter.bind( this )) })
        this.DOM.search ? this.DOM.search.addEventListener( 'input', this._search.bind( this ) ) : null
    }

    // Hide elements on initial display greathers than "initialDisplayLength"
    _loadInitial() {
        this.itemsDisplayed = parseInt( this.DOM.el.getAttribute( 'data-load-more-initial' ) )
        
        this.DOM.items.forEach( ( item, key ) => {
            key >= this.itemsDisplayed ? _.addClass( item, 'besa__filter-item--hidden' ) : null
        })
    }

    // Checbkox triggered
    _inputTrigger() {
        this._loadAnimation( 'filter' )
        let checkbox = event.target,
            value = event.target.value,
            checked = event.target.checked
        
        checked ? this.filtered.push( value ) : this.filtered = this.filtered.filter( ( e ) => { return e !== value })

        this.filtered.length > 0 ? this._itemFilter() : this._itemsUnfilter()
    }

_itemFilter() {
        this.DOM.items.forEach( ( item ) => {
            let itemDataFilter = item.getAttribute( 'data-filter' ),
                itemFilterArray = itemDataFilter.split( ',' ),
                coincidence = 0
            
            // Compare filter array with item filters
            for ( let value of this.filtered ) {
                if ( itemFilterArray.includes( value ) ) {
                    coincidence += 1
                } 
            }
            
            // Show / Hide depending on filters coincidence
            if ( this.filtered.length > 0 ) {
                coincidence > 0 ? _.removeClass( item, 'besa__filter-item--filtered' ) : _.addClass( item, 'besa__filter-item--filtered' )
            } else {
                _.removeClass( item, 'besa__filter-item--filtered' )
            }

        })

        // Hide ( Show Button 
        //this._loadButtonDisplay()

        // Show or hide no results message
        this._itemsResults()

        // Change label
        this._itemsCounter()

        // Scroll to top
        this._itemFilterScroll()

    }

    _itemFilterScroll() {
        gsap.to( window, { 
            duration: 1, 
            scrollTo: {
                y: this.DOM.el, 
                offsetY: this.itemsLoadScrollOffset
            }
        })
    }

    // Delete filters
    _itemsUnfilter() {
        this.DOM.inputs.forEach( ( input ) => { 
            input.checked = false
            this.filtered = []
        })

        this._itemFilter()
    }
    

    // Load more items
    _loadMore() {
        let itemsHidden = _.selectAll( '.besa__filter-item--hidden', this.DOM.el )
        itemsHidden.forEach( ( item, key ) => {
            key < this.itemsDisplayed ? _.removeClass( item, 'besa__filter-item--hidden' ) : null
        })
        // Load more button state
        this._loadButtonDisplay()
        this._loadScroll( itemsHidden )
    }

    // Scroll to loaded more first element
    _loadScroll( itemsHidden ) {
        gsap.to( window, { 
            duration: 1, 
            scrollTo: {
                y: itemsHidden[0], 
                offsetY: this.itemsLoadScrollOffset
            }
        })
    }

    // Show loader
    _loadAnimation( type ) {
        document.documentElement.style.overflow = 'hidden'
        this.DOM.loader.text.innerHTML = type === 'filter' ? this.messages.loader.filter : this.messages.loader.search
        _.addClass( this.DOM.loader.el, 'is--active' )
        setTimeout( () => {
            _.removeClass( this.DOM.loader.el, 'is--active' )
            document.documentElement.style.overflow = 'initial'
        }, 1000)
    }

    // Load more button display
    _loadButtonDisplay() {
        let itemsHidden = _.selectAll( '.besa__filter-item--hidden:not(.besa__filter-item--filtered)', this.DOM.el ).length
        itemsHidden === 0 ? _.addClass( this.DOM.load, 'is--hidden' ) : _.removeClass( this.DOM.load, 'is--hidden' )
    }

    // Show counted items displaying
    _itemsCounter() {
        let itemsDisplayed = _.selectAll( '.besa__filter-item:not(.besa__filter-item--filtered)').length
        this.DOM.counter.num.innerHTML = itemsDisplayed
        this.DOM.counter.label.innerHTML = itemsDisplayed === 1 ? this.messages.counter.singular : this.messages.counter.plural
    }

    // Display items result
    _itemsResults() {
        let itemsFiltered = _.selectAll( '.besa__filter-item:not(.besa__filter-item--filtered)').length
        itemsFiltered === 0 ? _.addClass( this.DOM.noresults, 'is--active' ) : _.removeClass( this.DOM.noresults, 'is--active' )
    }

    // Search
    _search() {
        let value = event.target.value.toLowerCase()
        this._loadAnimation( 'search' )

        this.DOM.items.forEach( ( item ) => {
            let match = false,
            name = _.select( '.name', item ),
            title = _.select( '.description', item ),
            cat = _.select( '.cat', item )

            name.innerText.toLowerCase().match( value ) || title.innerText.toLowerCase().match( value ) || cat.innerText.toLowerCase().match( value ) ? match = true : null

            !match ? _.addClass( item, 'besa__filter-item--filtered' ) : _.removeClass( item, 'besa__filter-item--filtered' ) 

        })

        // Hide ( Show Button 
        this._loadButtonDisplay()

        // Show or hide no results message
        this._itemsResults()

        // Change label
        this._itemsCounter()
    }

}

const filterGrid = new FilterGridClass()
export default filterGrid