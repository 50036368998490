import _ from '../../core/utils'
import floatedCtaConfig from './config'

class FloatedCtaClass {
    constructor() {
        this.DOM = {
            el: floatedCtaConfig.el
        }

        this.init()
    }

    init() {
        this.DOM.el ? this._position() : null
    }

    _position() {
        let firstSectionHeight = document.querySelector( 'main section' ).clientHeight,
            containerRightGap = document.querySelector( 'main section .container' ).getBoundingClientRect().left,
            floatedHeight = this.DOM.el.clientHeight

        window.innerWidth > 1024 ? this.DOM.el.style.top = `${firstSectionHeight - floatedHeight}px` : null
        window.innerWidth > 1440 ? this.DOM.el.style.right = `${containerRightGap}px` : null
    }
}

const floatedcta = new FloatedCtaClass()
export default floatedcta