import _ from '../../core/utils'
import productConfig from './config'

class ProductClass {
    constructor() {
        this.DOM = {
            page: productConfig.page,
            productBg: productConfig.productBg
        }

        this.init()
    }

    init() {
        console.log( this )
        this.DOM.page ? this._actions() : null
    }

    _actions() {
        this._productBgPosition()
    }

    _productBgPosition() {
        let left = this.DOM.productBg.getBoundingClientRect().left
        this.DOM.productBg.style.left = `-${left}px`
    }
}

const product = new ProductClass()
export default product