import _ from '../../core/utils'
import actualityConfig from './config'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'
gsap.registerPlugin( ScrollToPlugin )

class ActualityClass {
    constructor() {
        this.DOM = {
            el: actualityConfig.el,
            posts: _.selectAll( '.post:not(.post--highlight)', actualityConfig.el ),
            load: _.select( '.besa__filter-load', actualityConfig.el ),
            filters: _.selectAll( '.filters input', actualityConfig.el ),
            filterAll: _.select( '.filters .filter--all', actualityConfig.el )
        }

        this.loadIncrease = 0
        this.initialPosts = 0
        this.filtered = []
        this.DOM.el ? this.init() : null
    }

    init() {
        this._initialPosts()
        this._listeners()
        console.log( this )
    }

    _listeners() {
        this.DOM.load.addEventListener( 'click', this._loadMore.bind( this ) )
        this.DOM.filters.forEach( ( filter ) => { filter.addEventListener( 'click', this._filterTrigger.bind( this )) })
        this.DOM.filterAll.addEventListener( 'click', this._filterAll.bind( this ) )
    }
    

    _initialPosts() {
        this.initialPosts = parseInt( this.DOM.el.getAttribute( 'data-initial-posts' ) )
        this.DOM.posts.forEach( ( post, index ) => {
            index >= this.initialPosts ? _.addClass( post, 'post--hidden' ) : _.removeClass( post, 'post--hidden' )
        })
    }

    _filterTrigger() {
        let checkbox = event.target,
            value = event.target.value,
            checked = event.target.checked
        
        checked ? this.filtered.push( value ) : this.filtered = this.filtered.filter( ( e ) => { return e !== value })

        this.filtered.length > 0 ? this._filterPosts() : this._unfilterPosts()
    }

    _filterPosts() {
        this.DOM.posts.forEach( ( post ) => {
            let postDataFilter = post.getAttribute( 'data-cat' ),
                postFilterArray = postDataFilter.split( ',' ),
                coincidence = 0
            
            // Compare filter array with post filters
            for ( let value of this.filtered ) {
                if ( postFilterArray.includes( value ) ) {
                    coincidence += 1
                } 
            }
            
            // Show / Hide depending on filters coincidence
            coincidence === this.filtered.length ? _.removeClass( post, 'post--filtered' ) : _.addClass( post, 'post--filtered' )

            // Hide ( Show Button 
            this._loadButtonDisplay()

        })

        this.DOM.filters.forEach( ( input ) => {
            let ancestor = input.parentNode
            input.checked ? _.addClass( ancestor, 'is--active' ) : _.removeClass( ancestor, 'is--active' ) 
        })

        _.selectAll( '.filters input:checked', this.DOM.el ).length == 0 ? _.addClass( this.DOM.filterAll, 'is--active' ) : _.removeClass( this.DOM.filterAll, 'is--active' )
    }

    _unfilterPosts() {
        this.DOM.filters.forEach( ( input ) => { 
            input.checked = false
            this.filtered = []
        })

        this._filterPosts()
    }

    _filterAll() {
        this.DOM.filters.forEach( ( input ) => { 
            input.checked = false
            this.filtered = []
        })

        this._filterPosts()
    }

    // Load more items
    _loadMore() {
        let itemsHidden = _.selectAll( '.post--hidden', this.DOM.el )
        itemsHidden.forEach( ( item, key ) => {
            key < this.initialPosts ? _.removeClass( item, 'post--hidden' ) : null
        })
        // Load more button state
        this._loadButtonDisplay()
    }

    // Load more button display
    _loadButtonDisplay() {
        let itemsHidden = _.selectAll( '.post--hidden:not(.post--filtered)', this.DOM.el ).length
        itemsHidden === 0 ? _.addClass( this.DOM.load, 'is--hidden' ) : _.removeClass( this.DOM.load, 'is--hidden' )
    }

}

const actuality = new ActualityClass()
export default actuality